import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ContactForm, Layout, SEO, AboutPerson } from "../components"
import LogotypeWhite from "../images/futureFoundryLogos/FF_Logo_Logotype_White"
import { contact } from "../content"
import { GatsbyImage } from "gatsby-plugin-image"

const Contact = () => {
  const { about, metadata, name, title } = contact
  const { background, secondaryBackground, image } = useStaticQuery(graphql`
    query {
      background: file(
        relativePath: { eq: "images/backgrounds/Background_Grid.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      secondaryBackground: file(
        relativePath: { eq: "images/backgrounds/FF_Background_Graphic.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image: file(relativePath: { eq: "images/contact/ricky.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout title={metadata.title}>
      <SEO description={metadata.description} title={metadata.title} />
      <div className="contact-page">
        <div className="background-image-wrapper">
          <GatsbyImage
            image={background.childImageSharp.gatsbyImageData}
            className="background-image"
          />
          <AboutPerson image={image} name={name} title={title} about={about} />
          <ContactForm />
        </div>
        <div className="background-image-wrapper">
          <GatsbyImage
            image={secondaryBackground.childImageSharp.gatsbyImageData}
            className="background-image"
          />
          <div className="logo-section">
            <LogotypeWhite alt="Future Foundry logo" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
